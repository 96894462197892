import React from 'react';
import PropTypes from 'prop-types';

import Background from '../Background';

const Container = ({ className, screenHeight, children, backgroundConfig }) => {
  return (
    <div
      className={`container mx-auto py-12 lg:py-20 xl:py-24 px-8 ${
        screenHeight ? 'min-h-screen' : ''
      } flex ${className}`}
    >
      {backgroundConfig && <Background backgroundConfig={backgroundConfig} />}
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  screenHeight: PropTypes.bool,
  backgroundConfig: PropTypes.object,
};

Container.defaultProps = {
  className: '',
  screenHeight: false,
  backgroundConfig: null,
};

export default Container;
