import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { VscArrowRight } from 'react-icons/vsc';

const Button = props => {
  const elem = ({ variant, as, children, className, to, onClick }) => {
    const style = `btn-link btn-${
      variant ? variant : 'light'
    } fs-24 inline-block ${className}`;
    switch (as) {
      case 'link':
        return (
          <Link to={`${to ? to : '/'}`} className={style}>
            <span className="flex items-center justify-start text-primary">
              <span>{children}</span>
              <VscArrowRight strokeWidth={0} className="fs-40 ml-2" />
            </span>
          </Link>
        );
      default:
        return (
          <button className={style} onClick={onClick}>
            <span className="flex items-center justify-start">
              <span>{children}</span>
              <VscArrowRight strokeWidth={0} className="fs-40 ml-2" />
            </span>
          </button>
        );
    }
  };

  return elem(props);
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  as: PropTypes.oneOf(['link', 'button']),
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  variant: 'dark',
  as: 'link',
  className: '',
  onClick: () => null,
};

export default Button;
