import React, { useEffect, useRef, useState } from 'react';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// import Logo from '../../images/hie-hq-logo.svg';

// import ServicesMenu from '../ServicesMenu';
// import SolutionsMenu from '../SolutionsMenu';

const MenuIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7.5C4.5 7.10217 4.65804 6.72064 4.93934 6.43934C5.22064 6.15804 5.60218 6 6 6H24C24.3978 6 24.7794 6.15804 25.0607 6.43934C25.342 6.72064 25.5 7.10217 25.5 7.5C25.5 7.89782 25.342 8.27935 25.0607 8.56066C24.7794 8.84196 24.3978 9 24 9H6C5.60218 9 5.22064 8.84196 4.93934 8.56066C4.65804 8.27935 4.5 7.89782 4.5 7.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 15C4.5 14.6022 4.65804 14.2206 4.93934 13.9393C5.22064 13.658 5.60218 13.5 6 13.5H24C24.3978 13.5 24.7794 13.658 25.0607 13.9393C25.342 14.2206 25.5 14.6022 25.5 15C25.5 15.3978 25.342 15.7794 25.0607 16.0607C24.7794 16.342 24.3978 16.5 24 16.5H6C5.60218 16.5 5.22064 16.342 4.93934 16.0607C4.65804 15.7794 4.5 15.3978 4.5 15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 22.5C13.5 22.1022 13.658 21.7206 13.9393 21.4393C14.2206 21.158 14.6022 21 15 21H24C24.3978 21 24.7794 21.158 25.0607 21.4393C25.342 21.7206 25.5 22.1022 25.5 22.5C25.5 22.8978 25.342 23.2794 25.0607 23.5607C24.7794 23.842 24.3978 24 24 24H15C14.6022 24 14.2206 23.842 13.9393 23.5607C13.658 23.2794 13.5 22.8978 13.5 22.5Z"
        fill="white"
      />
    </svg>
  );
};

const menu = [
  // { label: 'About Us', path: '/about-us', external: false },
  { label: 'Expertise', path: '/expertise', external: false },
  { label: 'Approach', path: '/approach', external: false },
  { label: 'Portfolio', path: '/portfolio', external: false },
  { label: 'Startup', path: '/startup', external: false },
  { label: 'Blog', path: 'https://hiehq.com/blog', external: true },

  // { label: 'Blog', path: '/blog', external: false },
];

const Header = ({ siteTitle, darkHeader, location }) => {
  const headerRef = useRef(null);
  const [active, setActive] = useState(false);
  const [isPageEnd, setIsPageEnd] = useState(false);
  const [isSideNavActive, setIsSideNavActive] = useState(false);

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      setIsPageEnd(true);
    } else if (!bottom) {
      setIsPageEnd(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "hiehq-brand-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <Headroom
        onPin={() => setActive(true)}
        onUnpin={() => setActive(false)}
        style={{
          WebkitTransition: 'all .5s ease-in-out',
          MozTransition: 'all .5s ease-in-out',
          OTransition: 'all .5s ease-in-out',
          transition: 'all .5s ease-in-out',
          zIndex: 99,
        }}
        disableInlineStyles={true}
        className={`${isPageEnd ? 'headroom--bottom' : ''} ${
          darkHeader ? 'headroom--dark' : ''
        }`}
      >
        <header
          id="header"
          ref={headerRef}
          className={`${active ? 'pinned' : 'unpinned'}`}
        >
          <div
            className={
              'container mx-auto px-6 xl:px-8 flex items-center justify-start'
            }
          >
            <span>
              <Link to={'/'} className={'inline-block'}>
                {/* <img src={Logo} alt={siteTitle} style={{ maxWidth: 150 }} /> */}
                <GatsbyImage
                  image={data.logo.childImageSharp.gatsbyImageData}
                  alt={siteTitle}
                />
              </Link>
            </span>
            <span className="ml-auto hidden lg:block">
              <ul className="flex items-center justify-start ">
                {/* <li className="mr-8 relative services-drop-menu">
                  <a
                    className={'btn-link btn-light fs-16 cursor-pointer'}
                    role="button"
                  >
                    Services
                  </a>
                  <div className="gutter-space">
                    <div className="highlight-menu" />
                  </div>
                  <div className="mega-menu-container services">
                    <ServicesMenu />
                  </div>
                </li>

                <li className="mr-8 relative solutions-drop-menu">
                  <a
                    className={'btn-link btn-light fs-16 cursor-pointer'}
                    role="button"
                  >
                    Solutions
                  </a>
                  <div className="gutter-space">
                    <div className="highlight-menu" />
                  </div>
                  <div className="mega-menu-container solutions">
                    <SolutionsMenu />
                  </div>
                </li> */}

                {menu.map((item, i) => {
                  return (
                    <li key={`menu-item-${i}`} className="mr-8">
                      <Link
                        to={item.path}
                        className={`menu-item btn-link btn-light fs-16 ${
                          location.pathname === item.path ? 'active-menu' : ''
                        }`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  );
                })}
                <li>
                  <Link
                    to={'/contact-us'}
                    className={'btn btn-rounded btn-white btn-sm fs-16 fw-400'}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </span>

            <span className="ml-auto block lg:hidden">
              <span
                className="cursor-pointer"
                onClick={() => setIsSideNavActive(true)}
              >
                <MenuIcon />
              </span>
            </span>
          </div>
        </header>
      </Headroom>
      <div className={`sidenav ${isSideNavActive ? 'sidenav--active' : ''}`}>
        <span className="sidenav__logo">
          <Link to={'/'} className={'inline-block'}>
            {/* <img src={Logo} alt={siteTitle} style={{ maxWidth: 150 }} /> */}
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              alt={siteTitle}
            />
          </Link>
        </span>
        <a
          className="close-btn cursor-pointer"
          onClick={() => setIsSideNavActive(false)}
        >
          &times;
        </a>
        <div className="menu-items">
          <div className="flex flex-col justify-between">
            <div className="flex-1 flex flex-col items-center justify-center">
              {menu.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.path}
                    className={`menu-item btn-link btn-light ${
                      location.pathname === item.path ? 'active-menu' : ''
                    }`}
                  >
                    {item.label}
                  </Link>
                );
              })}
            </div>
            <div className="flex flex-col-reverse items-center">
              <Link to={'/contact-us'} className="contact-us-btn">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  darkHeader: PropTypes.bool,
  location: PropTypes.object,
};

Header.defaultProps = {
  siteTitle: '',
  darkHeader: false,
};

export default Header;
