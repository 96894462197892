import React from 'react';

import { ImLinkedin } from 'react-icons/im';
import {
  BsInstagram,
  BsDribbble,
  BsBehance,
  BsFacebook,
  BsMedium,
} from 'react-icons/bs';

import Section from '../Section';
import Container from '../Container';
import LinkButton from '../Buttons/Link';

const SocialMedia = [
  {
    title: 'LinkedIn',
    icon: <ImLinkedin />,
    url: 'https://www.linkedin.com/company/hie-hq',
  },
  {
    title: 'Instagram',
    icon: <BsInstagram />,
    url: 'https://instagram.com/hiehq',
  },
  {
    title: 'Dribbble',
    icon: <BsDribbble />,
    url: 'https://dribbble.com/hiehq',
  },
  {
    title: 'Behance',
    icon: <BsBehance />,
    url: 'https://www.behance.net/hiestudiohq',
  },
  {
    title: 'Facebook',
    icon: <BsFacebook />,
    url: 'https://www.facebook.com/hiehq',
  },
  {
    title: 'Medium',
    icon: <BsMedium />,
    url: 'https://medium.com/@hiehq',
  },
];

const Footer = () => {
  return (
    <>
      {/* <ContactUs /> */}
      <footer id="footer" className="flex flex-col">
        <Section id="footer">
          <Container
            className="flex-row lg:flex-col flex-wrap lg:flex-nowrap z-50 relative"
            backgroundConfig={{
              type: 'video',
              url: 'https://hiehq.s3.us-east-2.amazonaws.com/assets/contact-us.mp4',
              style: {
                height: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                paddingTop: '56.25%',
                maxWidth: 720,
              },
              className: 'block',
            }}
          >
            <div className="relative z-10">
              <h2
                className="ff-primary fs-40 fs-lg-72 fs-xl-96 fw-300 text-white mt-4"
                style={{ maxWidth: 700 }}
              >
                Have a <br /> Product Idea?
              </h2>
              <div className="mt-8 xl:mt-10">
                <LinkButton
                  as="link"
                  to="/contact-us"
                  className="fs-24 fs-lg-48 fw-300"
                >
                  Get in Touch
                </LinkButton>
              </div>
            </div>
            <div className="flex items-center justify-start text-white mt-10 lg:mt-16 w-full">
              {SocialMedia.map((item, i) => {
                return (
                  <span
                    key={`social-media-${i}`}
                    className="social-icon mr-4 lg:mr-8"
                  >
                    <a
                      href={item.url}
                      className="fs-24 inline-block"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item.icon}
                    </a>
                  </span>
                );
              })}
            </div>
          </Container>
        </Section>
        <div className="flex-1 flex flex-col lg:flex-row items-center lg:items-end justify-center pt-8 w-full mb-4">
          <span className="order-2 lg:order-1 mt-2 lg:mt-0">
            <span className="footer__links pr-4 lg:border-r">
              {new Date().getFullYear()}, HIEHQ All rights reserved
            </span>
          </span>
          <span className="flex order-1 lg:order-2">
            <span className="footer__links mr-4 lg:ml-4">
              <a className="btn-link" href="/sitemap.xml">
                Sitemap
              </a>
            </span>
            <span className="footer__links pl-4 border-l">
              <a className="btn-link" href="/terms-conditions">
                Terms
              </a>
            </span>
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
