import React from 'react';
import PropTypes from 'prop-types';

const Background = ({ backgroundConfig }) => {
  return (
    <div className={`section--background ${backgroundConfig.className}`} style={{...backgroundConfig.style}}>
      {backgroundConfig.type === 'video' && <video autoPlay controls={false} loop poster={backgroundConfig.poster} muted>
        <source src={backgroundConfig.url} type="video/mp4" />
      </video>}
    </div>
  );
};

Background.propTypes = {
  backgroundConfig: PropTypes.object.isRequired
};

// Background.defaultProps = {
//   id: `${parseInt(Math.random() * 100000000)}`,
//   backgroundColor: '#000000',
//   overflow: 'hidden',
// };

export default Background;
