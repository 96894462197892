import React from 'react';
import PropTypes from 'prop-types';

import Background from '../Background';
import BackgroundImage from 'gatsby-background-image';

const Section = ({
  id,
  backgroundColor,
  children,
  overflow,
  backgroundConfig,
  defaultStyle,
  backgroundImage,
}) => {
  const sectionId = `section__${id}`;
  const style = {
    backgroundColor,
    overflow,
    ...defaultStyle,
  };

  return backgroundImage ? (
    <BackgroundImage
      Tag={'section'}
      fluid={backgroundImage}
      className={sectionId}
      style={style}
    >
      {backgroundConfig && <Background backgroundConfig={backgroundConfig} />}

      {children}
    </BackgroundImage>
  ) : (
    <section className={sectionId} style={style}>
      {backgroundConfig && <Background backgroundConfig={backgroundConfig} />}

      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  overflow: PropTypes.string,
  backgroundConfig: PropTypes.object,
  defaultStyle: PropTypes.object,
  backgroundImage: PropTypes.any,
};

Section.defaultProps = {
  id: `${parseInt(Math.random() * 100000000)}`,
  backgroundColor: '#000000',
  overflow: 'hidden',
  backgroundConfig: null,
  defaultStyle: {},
  backgroundImage: null,
};

export default Section;
