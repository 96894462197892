import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, footer, pageId, theme, darkHeader, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title: siteTitle } = data.site.siteMetadata;

  return (
    <>
      {/* Header */}
      <Header
        theme={theme}
        siteTitle={siteTitle}
        darkHeader={darkHeader}
        location={location}
      />

      {/* Content */}
      <main id={`page--${pageId}`}>{children}</main>

      {/* Footer */}
      {footer && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  footer: PropTypes.bool,
  theme: PropTypes.string,
  darkHeader: PropTypes.bool,
};

Layout.defaultProps = {
  footer: true,
  theme: 'light',
  darkHeader: false,
};

export default Layout;
